.table-rejected-invoice-wrapper {
    overflow-y: auto;
    max-height: 500px;

    .table-rejected-invoice {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #242424;
    }

    .table-striped {
        border: 1px solid #242424;
    }

    .table-rejected-invoice-header {
        border: 2px slid #6d6d6d;
        background-color: #3b4a69 !important;
    }

    .table-rejected-invoice-stater-cell-font {
        color: #ffffff;
        font-size: 13px;
    }

    .table-invoice-sub-cell {
        text-align: center;
        color: #ffffff;
        font-size: 13px;
    }

    .booking-reference-cell-width {
        min-width: 100px;
    }

    .voucher-number-cell-width {
        min-width: 110px;
    }

    .check-in-date-cell-width {
        min-width: 120px;
    }

    .check-out-date-cell-width {
        min-width: 120px;
    }

    .invoice-no-cell-width {
        min-width: 100px;
    }

    .invoice-date-cell-width {
        min-width: 120px;
    }

    .status-cell-width {
        min-width: 120px;
    }

    .invoice-currency-cell-width {
        min-width: 100px;
    }

    .invoice-exchange-rate-cell-width {
        min-width: 140px;
    }

    .total-invoice-amount-with-vat-cell-width {
        min-width: 140px;
    }

    .total-invoice-amount-without-vat-cell-width {
        min-width: 150px;
    }

    .total-invoice-vat-amount-cell-width {
        min-width: 100px;
    }

    .dispute-type-cell-width {
        min-width: 100px;
    }

    .rejected-remark-cell-width {
        min-width: 100px;
    }

    .no-days-from-rejection-cell-width {
        min-width: 110px;
    }

    .action-cell-width {
        min-width: 100px;
        text-align: center;
        vertical-align: middle;
    }

    .dispute-type-cell-width {
        min-width: 100px;
    }

    .invoice-rejected-reason-cell-width {
        min-width: 160px;
    }
}