@import "components/bookingViewInvoice";

@import "components/rejectedInvoice";


// Here you can add other styles
@import "components/table-invoices";

.loader-container {
    width: 74%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.rmsc .dropdown-heading .dropdown-heading-value{
    white-space:pre-wrap !important;
}

.rmsc .dropdown-heading{
    height: auto !important;
}

.rmsc .dropdown-heading .dropdown-heading-dropdown-arrow{
    height: 28px;
}

.shop-td{
    width: 500px;
}
.custom-padding {
    padding: 20px;
}
.highlighted-label {
    font-size: 1rem;
    font-weight: bold;
    color: black;
}
.highlighted-label-header {
    font-size: 22px;
}
.button-element{
    margin: 0;
    padding: 5px;
}

