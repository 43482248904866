// invoice result list table

.table-wrapper {
    overflow-y: auto;
    max-height: 500px;

    .table-invoice {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #242424;

        .table-striped {
            border: 1px solid #242424;
        }

        .table-invoice-header {
            border: 2px slid #6d6d6d;
            background-color: #3b4a69 !important;
        }

        .table-invoice-stater-cell-font {
            color: #ffffff;
            font-size: 13px;
        }

        .table-invoice-sub-cell {
            text-align: center;
            color: #ffffff;
            font-size: 13px;
        }

        .booking-reference-cell-width {
            min-width: 100px;
        }

        .voucher-number-cell-width {
            min-width: 110px;
        }

        .supplier-name-cell-width {
            min-width: 100px;
        }

        .check-in-date-cell-width {
            min-width: 120px;
        }

        .check-out-date-cell-width {
            min-width: 120px;
        }

        .invoice-uploaded-by-cell-width {
            min-width: 150px;
        }

        .invoice-requested-date-cell-width {
            min-width: 120px;
        }

        .invoice-no-cell-width {
            min-width: 100px;
        }

        .invoice-date-cell-width {
            min-width: 120px;
        }

        .received-invoice-type-cell-width {
            min-width: 140px;
        }

        .credit-note-no-cell-width {
            min-width: 100px;
        }

        .credit-note-date-cell-width {
            min-width: 120px;
        }

        .received-credit-note-type-cell-width {
            min-width: 140px;
        }

        .status-cell-width {
            min-width: 120px;
        }

        .no-of-Due-Days-cell-width {
            min-width: 100px;
        }

        .invoice-currency-cell-width {
            min-width: 100px;
        }

        .invoice-exchange-rate-cell-width {
            min-width: 140px;
        }

        .total-invoice-amount-with-vat-cell-width {
            min-width: 140px;
        }

        .total-invoice-amount-without-vat-cell-width {
            min-width: 150px;
        }

        .total-invoice-vat-amount-cell-width {
            min-width: 100px;
        }

        .credit-note-exchange-rate-cell-width {
            min-width: 120px;
        }

        .total-credit-note-amount-with-vat-cell-width {
            min-width: 130px;
        }

        .total-credit-note-amount-without-vat-cell-width {
            min-width: 150px;
        }

        .total-credit-note-vat-amount-cell-width {
            min-width: 150px;
        }

        .invoice-process-vat-amount-cell-width {
            min-width: 150px;
        }

        .invoice-process-without-vat-amount-cell-width {
            min-width: 160px;
        }
    }
}