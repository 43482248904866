.custom-button-view-invoice {
    white-space: nowrap;
    max-width: 100px;
    text-align: center;
}

.custom-button-view-credit-note {
    white-space: nowrap;
    max-width: 120px;
    text-align: center;
}


// invoices table columns of voucher against 

.invoice-number-cell-width {
    width: auto;
    min-width: 10ch; 
    max-width: 100%; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
}

.invoice-exchange-rate-cell-width {
    min-width: 130px;
}

.invoice-amount-without-vat-cell-width{
    min-width: 140px;
}

.invoice-vat-amount-cell-width {
    min-width: 120px;
}

.invoice-amount-with-vat-cell-width {
    min-width: 140px;
}

.invoice-uploaded-by-cell-width {
    min-width: 130px;
}

.invoice-upload-date-cell-width {
    min-width: 130px;
}

.received-invoice-type-cell-width {
    min-width: 140px;
}

.received-credit-note-type-cell-width {
    min-width: 140px;
}

.credit-note-number-cell-width {
    min-width: 130px;
}

.credit-note-date-cell-width {
    min-width: 130px;
}

.credit-note-exchange-rate-cell-width {
    min-width: 140px;
}

.credit-note-amount-without-vat-cell-width {
    min-width: 170px;
}

.credit-note-vat-amount-cell-width {
    min-width: 140px;
}

.credit-note-amount-with-vat-cell-width {
    min-width: 150px;
}

.credit-note-uploaded-by-cell-width {
    min-width: 140px;
}

.credit-note-entered-date-cell-width {
    min-width: 140px;
}

.sticky-header {  
    top: 0;
    z-index: 10;
    background-color: rgb(59, 74, 105); 
  }